import useAPIs from "./useAPIs";

const useAnnouncement = (distributorId) => {
  const { postData, getData, postFormData } = useAPIs();

  const fetchAnnouncements = (payload, onSuccess, onError) => {
    postData(
      "/enrollment/v2/fetch-dist-announcements",
      payload,
      (data, response) => {
        onSuccess && onSuccess(data, response);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };

  return {
    fetchAnnouncements,
  };
};

export default useAnnouncement;
