import React, { useState } from "react";

const AnnouncementItem = (props) => {
  const [expanded, setExpanded] = useState(false);

  const handleLinkClick = (e) => {
    e.preventDefault();
    window.open(props.url, "_blank", "noopener,noreferrer");
  };

  const title = props.title === undefined ? "Heading" : props.title;
  const icon = props.icon === undefined ? "la-download" : props.icon;
  const addPadding = props.addTopPadding === undefined ? false : true;

  const toggleContent = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={"wish-announcement-item row d-flex align-items-center " + (addPadding === true ? "pt-2" : "")}>
      <div className="col-12">
        <h5>
          {title} {props.item.is_revised ? <small>- (Revised)</small> : null}
        </h5>
      </div>
      <div className="col-12">
        <span>
          <pre className={expanded ? "" : "pre-limited-height"}>{props.children}</pre>
        </span>
        {!expanded && (
          <div className="d-flex justify-content-flex-end">
            <button className="btn btn-link px-0" onClick={toggleContent}>
              View more
            </button>
          </div>
        )}
      </div>
      <div className="col-12">
        <p className={"m-0"}>
          Valid between {props.item.valid_from ? new Date(props.item.valid_from).toDateString() : "No data found"} and{" "}
          {props.item.valid_upto ? new Date(props.item.valid_upto).toDateString() : "No data found"}
        </p>
      </div>
      <div className="col-12">
        <a className={`btn btn-link px-0 ${props.url ? "" : "disabled"}`} onClick={handleLinkClick}>
          <i className={"la " + icon}></i> Download Attachment
        </a>
      </div>
    </div>
  );
};

export default AnnouncementItem;
