import { useEffect, useState } from "react";
import useAPIs from "./useAPIs";
import { AppUtils } from "./AppUtils";
import WishToaster from "../components/WishToaster";

const useSales = (distributorId) => {
  const { postData, getData, postFormData, downloadFile } = useAPIs();
  const [orderTypes, SetOrderTypes] = useState(null);

  const fetchMyOrders = (payload, onSuccess, onError) => {
    postData(
      "/reports/vo-fetch-dist-my-orders",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };

  const fetchOrderTypes = () => {
    getData("/sales/v1/master/ordertypes", (data) => {
      SetOrderTypes(data);
    });
  };
  const fetchOrGenerateStoreLink = (flag, onSuccess, onError) => {
    let payload = {};
    payload.distributor_id = JSON.parse(localStorage.getItem("distributor"))?.distributor_id;
    postData(
      flag ? `/enrollment/fetch-store-link` : `/enrollment/generate-store-link`,
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const getOrderDetails = (payload, onSuccess, onError) => {
    postData(
      `/reports/fetch-dist-order-details`,
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const fetchOrderStatuses = (onSuccess, onError) => {
    getData(
      "/sales/v1/master/shipment-statuses",
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const fetchReplacmentReasons = (onSuccess, onError) => {
    getData(
      "/sales/v1/master/replacement-reasons",
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const cancelOrder = (payload, onSuccess, onError) => {
    postData(
      "/sales/v1/order-cancellation",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };
  const replacementRequest = (payload, onSuccess, onError) => {
    postData(
      "/sales/v1/order-damage-replacement-request",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };
  const replacementRequestFileUpload = (payload, onSuccess, onError) => {
    postFormData(
      "/sales/v1/order-damage-replacement-doc-upload",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };
  const downloadInvoice = (URL, onSuccess, onError) => {
    downloadFile(
      AppUtils.filterBaseUrl(URL),
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const initiateBulkOrderPrint = (payload, onSuccess, onError) => {
    postData(
      "/reports/download-bulk-order",
      payload,
      (data, response) => {
        onSuccess && onSuccess(data, response);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };
  const downloadVOTMConsolidated = (payload, onSuccess, onError) => {
    postData(
      "/reports/fetch-consolidated-votm-invoice-log",
      payload,
      (data, response) => {
        onSuccess && onSuccess(data, response);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };
  const downloadCreditNote = (payload, onSuccess, onError) => {
    postData(
      "/reports/fetch-credit-note",
      payload,
      (data, response) => {
        onSuccess && onSuccess(data, response);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };

  return {
    fetchOrGenerateStoreLink,
    fetchMyOrders,
    fetchOrderTypes,
    getOrderDetails,
    fetchReplacmentReasons,
    fetchOrderStatuses,
    cancelOrder,
    replacementRequest,
    replacementRequestFileUpload,
    downloadInvoice,
    initiateBulkOrderPrint,
    downloadVOTMConsolidated,
    downloadCreditNote,
  };
};

export default useSales;
