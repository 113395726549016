import { useEffect, useState } from 'react'
import WishToaster from '../components/WishToaster'
import platform from 'platform'
// import * as Sentry from "@sentry/browser";

const useAPIs = () => {
	const [apiError, setError] = useState(null)
	const [processing, setProcessing] = useState(false)

	const generalHeaders = {
		'User-Agent': navigator.userAgent, // User-Agent string,
		'X-Timestamp': new Date().toISOString(),
		'Accept-Language': navigator.language,
		Referer: document.referrer,
		'X-Screen-Resolution': `${window.screen.width}x${window.screen.height}`, // Screen resolution,
		'X-OS': platform.os.toString(),
		'X-Browser': `${platform.name}-${platform.version}`,
		'X-Description': platform.description,
		// Add more headers as needed
	}

	const handleError = (error, onFailure, response, inputData) => {
		setProcessing(false)
		onFailure && onFailure(error?.message)
		// console.log(error, 'handleError')
		// Customize Sentry error capture with additional data
		// Sentry.captureException(error, {
		//   tags: { endpoint: response?.url },
		//   extra: {
		//     payload: JSON.stringify(inputData ?? {}),
		//     response: {
		//       status: response?.status,
		//       headers: response?.headers,
		//       data: response?.data,
		//     },
		//   },
		// });
		if (error.status === 401) {
			localStorage.clear()
			window.location.reload()
		}
		setError(error?.message)
	}

	const handleCatch = (error, onFailure) => {
		setProcessing(false)
		onFailure && onFailure(error)
		console.log(error, 'handleCatch')
		setError(error)
	}

	const postData = async (url, inputData, onSuccess, onFailure) => {
		try {
			setError(null)
			setProcessing(true)
			await fetch(process.env.REACT_APP_BASE_URL.concat(url), {
				method: 'POST',
				headers: {
					...generalHeaders,
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'x-auth': localStorage.getItem('x-auth'),
				},
				body: JSON.stringify(inputData ?? {}),
			})
				.then(res => {
					return res.json().then(data => ({ response: res, data }))
				})
				.then(({ response, data }) => {
					setProcessing(false)

					// Access the specific response header
					const xAuthHeader = response.headers.get('x-auth')
					if (xAuthHeader) {
						localStorage.setItem('x-auth', xAuthHeader)
					}
					if (data.status === 200 || data.status === 'success') {
						onSuccess && onSuccess(data.data, data)
					} else {
						handleError(data, onFailure, response, inputData)
					}
				})
				.catch(error => {
					handleError(error, onFailure)
				})
		} catch (error) {
			handleCatch(error, onFailure)
		}
	}

	const postFormData = (url, inputData, onSuccess, onFailure) => {
		try {
			setError(null)
			setProcessing(true)
			fetch(process.env.REACT_APP_BASE_URL.concat(url), {
				method: 'POST',
				headers: {
					...generalHeaders,
					'x-auth': localStorage.getItem('x-auth'),
				},
				body: inputData,
			})
				.then(res => res.json().then(data => ({ response: res, data })))
				.then(({ response, data }) => {
					setProcessing(false)
					if (data.status === 200 || data.status === 'success') {
						onSuccess && onSuccess(data.data)
						return
					} else {
						handleError(data, onFailure, response, inputData)
						return
					}
				})
				.catch(error => {
					handleError(error, onFailure)
				})
		} catch (error) {
			handleCatch(error, onFailure)
		}
	}

	const getData = (url, onSuccess, onFailure) => {
		try {
			setError(null)
			setProcessing(true)
			fetch(process.env.REACT_APP_BASE_URL.concat(url), {
				method: 'GET',
				headers: {
					...generalHeaders,
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'x-auth': localStorage.getItem('x-auth'),
				},
			})
				.then(res => res.json())
				.then(data => {
					setProcessing(false)
					if (data.status === 200 || data.status === 'success') {
						onSuccess && onSuccess(data.data)
						return
					} else {
						handleError(data, onFailure)
						return
					}
				})
				.catch(error => {
					handleError(error, onFailure)
				})
		} catch (error) {
			handleCatch(error, onFailure)
		}
	}

	const downloadFile = async (fileUrl, onSuccess, onFailure) => {
		try {
			// Retrieve the token from localStorage
			const authToken = localStorage.getItem('x-auth')
			if (!authToken) {
				WishToaster({
					toastType: 'error',
					toastMessage: 'Authentication token not found in localStorage.',
				})
				return
			}
			const url = process.env.REACT_APP_BASE_URL.concat(fileUrl)
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					...generalHeaders,
					'X-Auth': authToken,
				},
			})

			if (response.ok) {
				const blob = await response.blob()

				// Extract filename from Content-Disposition header
				const contentDispositionHeader = response.headers.get(
					'Content-Disposition'
				)
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
				const matches = filenameRegex.exec(contentDispositionHeader)
				let filename = 'downloaded_file' // Default filename
				if (matches && matches[1]) {
					filename = matches[1].replace(/['"]/g, '') // Remove quotes if present
				}

				// Create a temporary anchor element to trigger the download
				const link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = filename
				document.body.appendChild(link)

				// Trigger the download
				link.click()

				// Remove the temporary anchor element
				document.body.removeChild(link)
				onSuccess && onSuccess(response)
			} else {
				WishToaster({
					toastType: 'error',
					toastMessage: `Failed to download the file. HTTP status: ${response.status}`,
				})
				onFailure && onFailure(response.data)
			}
		} catch (error) {
			WishToaster({
				toastType: 'error',
				toastMessage: `Error downloading the file: ${error}`,
			})
		}
	}

	return {
		apiError,
		processing,
		postData,
		postFormData,
		getData,
		downloadFile,
	}
}

export default useAPIs
