/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PageLayout from "../../components/PageLayout";
import WishCard from "../../components/WishCard";
import WishModal from "../../components/WishModal";
import WishToaster from "../../components/WishToaster";
import useSales from "../../services/useSales";
import CommonAPIs from "../../services/commonAPIs";
import { AppUtils } from "../../services/AppUtils";
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";

const SwalAlert = withReactContent(Swal);

export default function Sales() {
  const distributor = JSON.parse(localStorage.getItem("distributor"));
  const navigate = useNavigate();
  const { CheckEligStatus, checkRubyRewards } = CommonAPIs();
  const { fetchOrGenerateStoreLink } = useSales();
  const [storeLink, setStoreLink] = useState({});
  const [showRubyRewards, setShowRubyRewards] = useState(false);

  const breadcrumbs = [];
  breadcrumbs.push({ title: "Home", linkTo: "/" });
  breadcrumbs.push({ title: "Orders", linkTo: "/sales" });

  useEffect(() => {
    fetchOrGenerateStoreLink(true, (data) => {
      if (data) {
        setStoreLink(data);
      } else {
        fetchOrGenerateStoreLink(false, (data) => {
          setStoreLink(data);
        });
      }
    });
    checkRubyRewards(
      { distributor_id: distributor.distributor_id },
      (data) => {
        setShowRubyRewards(data ? true : false);
        data &&
          WishToaster({
            toastType: "congrats",
            toastMessage: "Your Ruby Rewards is here!",
          });
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const handlePlaceOrderClick = (e) => {
    SwalAlert.fire({
      icon: "info",
      title: `Confirm`,
      text: `Dear ${
        JSON.parse(localStorage.getItem("distributor"))?.name
      }, Please be informed that this order will not be considered for the activation of your ID and you may not receive retail revenue for this order if your ID is currently inactive. Kindly acknowledge to proceed.`,
      allowOutsideClick: false, // Prevent dismissing by clicking outside the dialog
      allowEscapeKey: false, // Prevent dismissing by pressing the Esc key
      showCloseButton: true, // Show the close button
      showConfirmButton: true,
      confirmButtonText: "Proceed",
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        CheckEligStatus(
          { distributor_id: JSON.parse(localStorage.getItem("distributor"))?.distributor_id },
          (data) => {
            if (data) {
              AppUtils.hideDialog("dlgGetDist");
              navigate("/placeorder", { state: { distId: JSON.parse(localStorage.getItem("distributor"))?.distributor_id, pvData: data } });
            } else {
              WishToaster({ toastTitle: "Error", toastType: "error", toastMessage: "Please enter a valid direct seller ID" });
            }
          },
          (error) => {
            WishToaster({ toastTitle: "Error", toastType: "error", toastMessage: "Please enter a valid direct seller ID" });
          }
        );
      }
    });
  };

  const handleFireworksInit = ({ conductor }) => {
    conductor.run({ duration: 3000, speed: 2 }); // Run fireworks for 5 seconds
  };

  return (
    <PageLayout activeSideMenu="2" pageTitle="Sales Dashboard" header="OPTIONS" breadcrumbs={breadcrumbs}>
      <section className="row">
        {distributor?.distributor_rank?.rank_id > 1 && (
          <>
            <div className="col-md-4 col-sm-6">
              <WishCard
                bgColor="bg-primary"
                textWhite
                icon="la-cart-plus"
                title="Place Order"
                maxHeight={true}
                line1="Order from here."
                line2={[" Before ordering, please confirm your", <code>Daily PV Limit</code>]}
                handleClick={handlePlaceOrderClick}
              ></WishCard>
            </div>
            {showRubyRewards && (
              <div className="col-md-4 col-sm-6">
                <WishCard
                  icon="la-cart-plus"
                  title={
                    <>
                      Avail Ruby Rewards<span className="badge badge-primary text-small ml-1 custom-tada">New</span>
                    </>
                  }
                  maxHeight={true}
                  line1="Congratulations! You have earned a Ruby Reward."
                  line2={["To redeem your Ruby Reward, please click Proceed"]}
                  linkTo="/redeemrubyrewards"
                ></WishCard>
              </div>
            )}
            <div className="col-md-4 col-sm-6">
              <WishCard
                title="My Orders"
                icon="la-list-alt"
                maxHeight={true}
                line1="Use this option to check the status of your orders and make payments for any pending orders. "
                linkTo="/myorders"
              ></WishCard>
            </div>
          </>
        )}

        <div className="col-md-4 col-sm-6">
          <WishCard
            title="Store Link"
            icon="la-link"
            maxHeight={true}
            line1="Generate and copy the store link from here and share it with your customers."
            linkToTitle="Copy Link"
            linkToModal="#dlgGetLink"
          ></WishCard>
        </div>
        <div className="col-md-4 col-sm-6">
          <WishCard
            title="Store Link Orders"
            icon="la la-store"
            maxHeight={true}
            line1="Monitor the orders placed by the customers via your store link."
            linkToTitle="Proceed"
            linkTo={"/storelinkorders"}
          ></WishCard>
        </div>
        {distributor?.distributor_rank?.rank_id > 1 && distributor?.is_votm_member && (
          <div className="col-md-4 col-sm-6">
            <WishCard
              title="VOTM Inventory"
              icon="la la-store"
              maxHeight={true}
              line1="Manage Your VOTM deliveries here"
              linkToTitle="Proceed"
              linkTo={"/votminventory"}
            ></WishCard>
          </div>
        )}
      </section>
      <WishModal
        id="dlgGetLink"
        title="Get Link"
        hideCancelButton={true}
        finishTitle="Copy Link"
        modalSize="modal-lg"
        onFinish={() => {
          navigator.clipboard.writeText(storeLink);
          WishToaster({
            toastMessage: "Link copied to clipboard!",
            toastType: "success",
          });
        }}
      >
        <div className="form-group row">
          <div className="col-12">
            <div className="input-group">
              <input id="txtStoreLink" name="txtStoreLink" type="text" className="form-control" value={storeLink} />
              <div className="input-group-append">
                <div
                  className="input-group-text"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    fetchOrGenerateStoreLink(false, (data) => {
                      setStoreLink(data);
                    });
                  }}
                >
                  {/* <Link to=""> */}
                  <i className="las la-sync"></i>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="text-center">
          <span className="align-middle">
            <i className="lab la-2x la-whatsapp"></i>
          </span>
          <span className="align-middle">
            {" "}
            Send to your registered mobile number <code>{JSON.parse(localStorage.getItem("distributor"))?.phone}</code>
          </span>
          <span className="pl-2">
            <button className="btn btn-primary btn-sm">
              Share
            </button>
          </span>
        </div> */}
      </WishModal>
      {showRubyRewards && <Fireworks onInit={handleFireworksInit} />}
    </PageLayout>
  );
}
