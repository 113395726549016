/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useLocalStorage from "react-use-localstorage";
import _ from "lodash";
import pageConfig from "../../data/config.json";
import { AppUtils } from "../../services/AppUtils";
import useGenealogy from "../../services/useGenealogy";
import { LOAD_EXTREME_LEFT, LOAD_EXTREME_RIGHT, LOAD_PREFERRED_LEFT, LOAD_PREFERRED_RIGHT } from "../../services/Constants";
import useMasters from "../../services/useMasters";
import PageLayout from "../../components/PageLayout";
import WishGeneologyTree from "../../components/WishGeneologyTree";
import WishToaster from "../../components/WishToaster";
import WishModal from "../../components/WishModal";
import WishListGroup from "../../components/WishListGroup";
import WishButtonGroup from "../../components/WishButtonGroup";
import RankBadges from "./RankBadges";
import TeamMemberStatus from "./TeamMemberStatus";
import ActivationPV from "./ActivationPV";
import GroupVolume from "./GroupVolume";
import EmptyAnimation from "../../components/EmptyAnimation";
import animation from "../../assets/app-assets/images/lottie/NoDataAvailable.json";
import useDashboard from "../../services/useDashboard";
import GenealogyLoader from "../../components/GenealogyLoader";

export default function MyGeneology({ isWebview = false }) {
  const [treeNodes, setTreeNodes] = useState(null);
  const [selectedNode, setSelectedNode] = useState(treeNodes);
  const [filterApplied, applyFilter] = useState(false);
  const [nodeDataToEnrollment, setNodeDataToEnrollment] = useState("");
  const [distributor, setDistributorDetails] = useLocalStorage("distributor", "");
  const [searchParams, setSearchParams] = useSearchParams();
  const { loggedInUser } = useMasters();
  const navigate = useNavigate();

  // const studentAccess = JSON.parse(localStorage.getItem("student_access"));

  // console.log('searchParams:', searchParams)// 0

  const {
    distributorGVStats,
    distributorMemberStats,
    distributorStats,
    enrollDistributor,
    genealogyError,
    getPendingEnrolleesFor,
    getTreeData,
    loading,
    genealogyLoading,
    navigateTreeTo,
    navigateTreeToOneLevelUp,
    pendingEnrolleesList,
    placementPositions,
    ranks,
    treeData,
    // fetchDistributorDetails,
  } = useGenealogy(loggedInUser);
  const { getDistributorDetails } = useDashboard(searchParams.get("distributor_id"));

  const [distLoading, setDistLoading] = useState(true);

  const [selectedDistributor, setSelectedDistributor] = useState(-1);

  const placementStructure = {
    distributor_id: "",
    placement_distributor_id: "",
    placement_position_id: 0,
    sponsor_distributor_id: "",
    dist_temp_id: 0,
  };
  const [selectedPosition, setSelectedPosition] = useState(_.cloneDeep(placementStructure));
  const [selectedNodeParentId, setSelectedNodeParentId] = useState(null);
  const [selectedPositionIndex, setSelectedPositionIndex] = useState(null);
  const [selectedEnrolleeIndex, setSelectedEnrolleeIndex] = useState(null);
  const [pageError, setError] = useState(null);

  const data = {
    labels: ["Active Members", "Inactive Members", "Other Members"],
    datasets: [
      {
        data: [distributorMemberStats?.Active ?? 0, distributorMemberStats?.Inactive ?? 0, distributorMemberStats?.Others ?? 0],
        backgroundColor: ["#87bcf1", "#fbe892", "#f893b0"],
        borderColor: ["#87bcf1", "#fbe892", "#f893b0"],
        borderWidth: 0,
        cutout: "85%",
      },
    ],
  };

  useEffect(() => {
    if (isWebview) {
      getDistributorDetails((data) => {
        setDistributorDetails(JSON.stringify(data));
        setDistLoading(false);
      });

      // localStorage.setItem("distributor", JSON.stringify({ distributor_id: parseInt(searchParams.get("distributor_id")) }));
    } else {
      setDistLoading(false);
    }
  }, []);

  useEffect(() => {
    setSelectedDistributor(isWebview ? parseInt(searchParams.get("distributor_id")) : loggedInUser?.distributor_id);
  }, [loggedInUser]);

  useEffect(() => {
    if (!distLoading) {
      if (JSON.parse(distributor)?.distributor_rank?.rank_id > 1 ) {
        if (selectedDistributor > -1) {
          getTreeData({
            distributor_id: selectedDistributor == 1001 ? 1002 : selectedDistributor,
            logged_in_distributor_id: isWebview ? parseInt(searchParams.get("logged_in_distributor_id")) : loggedInUser.distributor_id,
            depth: 2,
          });
        }
      }
    }
  }, [selectedDistributor, distLoading]);

  useEffect(() => {
    if (treeData) {
      setTreeNodes(treeData);
    }
  }, [treeData]);

  useEffect(() => {
    setError(genealogyError);
  }, [genealogyError]);

  useEffect(() => {
    if (pageError) {
      WishToaster({
        toastMessage: pageError,
      });
    }
  }, [pageError]);

  const showAll = function () {
    applyFilter(false);
    setSelectedNode(data.treeData);
    setTreeNodes(data.treeData);
  };

  const treeTopIcons = function () {
    return (
      <div className="row pl-2 pr-2">
        <button
          className={"d-flex align-items-center text-primary "}
          onClick={() => {
            if (loggedInUser?.distributor_id) {
              setSelectedDistributor(loggedInUser.distributor_id);
            } else {
              setSelectedDistributor(isWebview ? parseInt(searchParams.get("logged_in_distributor_id")) : loggedInUser.distributor_id);
            }
          }}
        >
          <i className="las la-angle-left"></i>&nbsp;Back to root
        </button>
        &nbsp;&nbsp;
        <button
          className="d-flex align-items-center ml-auto"
          onClick={() => {
            AppUtils.showDialog("dlgSearch");
            //$("#dlgSearch").modal("show");
          }}
        >
          <i className="las la-search"></i>&nbsp;Search
        </button>
        &nbsp;&nbsp;
        {isWebview ? null : (
          <>
            <button className="d-flex align-items-center btn btn-primary btn-sm shadow" data-toggle="dropdown" aria-expanded="false">
              <i className="las la-route"></i>&nbsp;Navigation
            </button>
            <div className="dropdown-menu">
              <a
                className="dropdown-item"
                href="#"
                onClick={() => {
                  navigateTreeTo(LOAD_EXTREME_LEFT, selectedDistributor, loggedInUser.distributor_id);
                }}
              >
                To Extreme Left
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => {
                  navigateTreeTo(LOAD_EXTREME_RIGHT, selectedDistributor, loggedInUser.distributor_id);
                }}
              >
                To Extreme Right
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => {
                  navigateTreeTo(LOAD_PREFERRED_LEFT, selectedDistributor, loggedInUser.distributor_id);
                }}
              >
                To Preferred Extreme Left
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => {
                  navigateTreeTo(LOAD_PREFERRED_RIGHT, selectedDistributor, loggedInUser.distributor_id);
                }}
              >
                To Preferred Extreme Right
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  const rootDistributor = treeNodes.root.distId;

                  if (rootDistributor && parseInt(rootDistributor) !== parseInt(loggedInUser.distributor_id)) {
                    navigateTreeToOneLevelUp(rootDistributor, loggedInUser.distributor_id);
                  } else {
                    WishToaster({
                      toastMessage: "You are already at the top of your organization tree.",
                      toastType: "error",
                    });
                  }
                }}
              >
                To One Level Back
              </a>
            </div>
            &nbsp;&nbsp;
            <Link className="d-flex align-items-center" to="/mygeneologysettings">
              <i className="las la-cog"></i>&nbsp;Settings
            </Link>
          </>
        )}
      </div>
    );
  };

  const enrollUserModal = () => {
    return (
      <WishModal
        id="dlgEnroll"
        title="Enroll New Direct Seller"
        noFooter={selectedEnrolleeIndex === null || selectedPositionIndex === null}
        onFinish={() => {
          const newPosition = _.cloneDeep(selectedPosition);

          if (distributor !== "") {
            const distributorFromLocalStorage = JSON.parse(distributor);
            newPosition.sponsor_distributor_id = distributorFromLocalStorage.distributor_id;
            newPosition.distributor_id = distributorFromLocalStorage.distributor_id;
            newPosition.placement_distributor_id = selectedNodeParentId;
            setSelectedPosition(newPosition);
            if (selectedPosition.dist_temp_id === -1 || selectedPosition.placement_position_id === -1) {
              WishToaster({ toastMessage: "No position selected" });
            } else {
              enrollDistributor(newPosition, selectedDistributor);
            }
          } else {
            WishToaster({ toastMessage: "Oops! Something went wrong." });
          }
        }}
      >
        {genealogyError ? (
          <h3 className="text-danger">{genealogyError}</h3>
        ) : (
          <>
            {pendingEnrolleesList && (
              <>
                <div className="d-flex justtify-content-flex-end">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={(e) => {
                      AppUtils.hideDialog("dlgEnroll");
                      navigate("/enrolluser", {
                        state: { placementDetails: nodeDataToEnrollment },
                      });
                    }}
                  >
                    New Enrollment
                  </button>
                </div>
                <WishListGroup
                  title="Assign Enrollees"
                  subTitle="Select from below list"
                  items={pendingEnrolleesList}
                  label={"first_name"}
                  selectedItemIndex={selectedEnrolleeIndex}
                  onSelect={(item) => {
                    setSelectedEnrolleeIndex(item.dist_temp_id);
                    const newPosition = _.cloneDeep(selectedPosition);
                    newPosition.dist_temp_id = item.dist_temp_id;
                    setSelectedPosition(newPosition);
                  }}
                />
              </>
            )}

            {pendingEnrolleesList?.length > 0 && placementPositions ? (
              <>
                <h5 className="pt-2">Select appropriate position below:</h5>
                <WishButtonGroup
                  selectedIndex={selectedPositionIndex}
                  onSelect={(button, index) => {
                    setSelectedPositionIndex(index);
                    const newPosition = _.cloneDeep(selectedPosition);
                    newPosition.placement_position_id = placementPositions[index].position_id;

                    setSelectedPosition(newPosition);
                  }}
                  buttons={placementPositions.map((x) => x.position_title)}
                />
              </>
            ) : null}
          </>
        )}
      </WishModal>
    );
  };
  const body = () => {
    return (
      <>
        <div className="row">
          {JSON.parse(distributor)?.distributor_rank?.rank_id > 1 ? (
            <>
              <div className="col-12">
                {treeNodes && !genealogyLoading ? (
                  <WishGeneologyTree
                    loading={loading}
                    header={treeTopIcons()}
                    reverse={true}
                    tree={treeNodes.root}
                    showBackButton={filterApplied}
                    onNodeSelected={(distributorId, isActionNode, clickedNode) => {
                      if (!isActionNode) {
                        setSelectedDistributor(distributorId);
                      } else {
                        if (distributor !== "") {
                          let placementDetails = {};
                          placementDetails.distributor_id = JSON.parse(distributor)?.distributor_id;
                          placementDetails.placement_distributor_id = clickedNode.parentDistId;
                          placementDetails.placement_position_id = clickedNode.relationId;
                          placementDetails.sponsor_distributor_id = JSON.parse(distributor)?.distributor_id;
                          placementDetails.sponsor_distributor_name = JSON.parse(localStorage.getItem("distributor"))?.name;

                          setNodeDataToEnrollment(placementDetails);
                          getPendingEnrolleesFor(loggedInUser.distributor_id, 5, distributorId, (pendingEnrolleesListData) => {
                            setSelectedNodeParentId(distributorId);
                            const newPosition = _.cloneDeep(placementStructure);
                            newPosition.dist_temp_id = -1;
                            newPosition.placement_position_id = -1;

                            setSelectedPosition(newPosition);
                            setSelectedPositionIndex(null);
                            setSelectedEnrolleeIndex(null);
                            AppUtils.showDialog("dlgEnroll");
                          });
                        }
                      }
                    }}
                    onResetRequested={() => {
                      showAll();
                    }}
                    onSearchClicked={(searchString) => {
                      if (parseInt(searchString)) setSelectedDistributor(searchString);
                      else {
                        WishToaster({
                          toastMessage: "Invalid direct seller Id",
                          toastType: "error",
                        });
                      }
                    }}
                  />
                ) : (
                  <GenealogyLoader />
                )}
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                <GroupVolume distributorGVStats={distributorGVStats} selectedDistributor={selectedDistributor} />
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                <div className="row">
                  <ActivationPV distributorStats={distributorStats} />
                  <div className="col-12">
                    <TeamMemberStatus data={data} distributorMemberStats={distributorMemberStats} className={"rounded-1 border-light"} width={"200px"} height={"200px"} />
                  </div>
                </div>
              </div>
              <RankBadges ranks={ranks} />
            </>
          ) : (
            <div className="col-12">
              <EmptyAnimation message={"No data to display. Please come back when you're a star distributor or above"} lottieAnimation={animation} />
            </div>
          )}
        </div>
        {enrollUserModal()}
      </>
    );
  };
  return isWebview ? (
    <>
      {!distLoading ? (
        JSON.parse(distributor)?.distributor_rank?.rank_id > 1  ? (
          <div className="row">
            <div className="col-12">
              {treeNodes ? (
                <WishGeneologyTree
                  loading={loading}
                  header={treeTopIcons()}
                  reverse={true}
                  tree={treeNodes.root}
                  showBackButton={filterApplied}
                  onNodeSelected={(distributorId, isActionNode, clickedNode) => {
                    if (!isActionNode) {
                      setSelectedDistributor(distributorId);
                    } else {
                      if (distributor !== "") {
                        getPendingEnrolleesFor(loggedInUser.distributor_id, 5, distributorId, (pendingEnrolleesListData) => {
                          if (pendingEnrolleesListData && pendingEnrolleesListData.length > 0) {
                            setSelectedNodeParentId(distributorId);
                            const newPosition = _.cloneDeep(placementStructure);
                            newPosition.dist_temp_id = -1;
                            newPosition.placement_position_id = -1;
                            setSelectedPosition(newPosition);
                            setSelectedPositionIndex(null);
                            setSelectedEnrolleeIndex(null);
                            AppUtils.showDialog("dlgEnroll");
                          } else {
                            setError("No pending enrollees found.");
                            return;
                          }
                        });
                      }
                    }
                  }}
                  onResetRequested={() => {
                    showAll();
                  }}
                  onSearchClicked={(searchString) => {
                    if (parseInt(searchString)) setSelectedDistributor(searchString);
                    else {
                      WishToaster({
                        toastMessage: "Invalid direct seller Id",
                        toastType: "error",
                      });
                    }
                  }}
                />
              ) : (
                <GenealogyLoader />
              )}
            </div>
          </div>
        ) : (
          <div className="col-12">
            <EmptyAnimation message={"No data to display. Please come back when you're a star distributor or above"} lottieAnimation={animation} />
          </div>
        )
      ) : null}
      {enrollUserModal()}
    </>
  ) : (
    <PageLayout {...pageConfig.mygenealogy}>{body()}</PageLayout>
  );
}
