import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import WishUploadFiles from "../../components/WishUploadFiles";
import WishToaster from "../../components/WishToaster";
import useVmail from "../../services/useVmail";
import { AppUtils } from "../../services/AppUtils";

export default function VmailThreads({ mailDetail, setIsThreadView, getMails, selectedMail, setMailDetail }) {
  const SwalAlert = withReactContent(Swal);

  const { postGeneralFormdata, postGeneral } = useVmail();
  const [loading, setLoading] = useState(false);
  const [resetFilesFlag, setResetFilesFlag] = useState(null);
  const [message, setMessage] = useState({
    distributor_id: JSON.parse(localStorage.getItem("distributor"))?.distributor_id,
    parent_vmail_id: selectedMail?.parent_vmail_id,
    subject_id: selectedMail?.mvmail_subject_id,
    subject_title: selectedMail?.title,
    body: "",
    has_attachment: 0,
    attachments: [],
  });

  useEffect(() => {
    if (mailDetail.length > 0) {
      const lastMailStatusId = mailDetail[mailDetail.length - 1]?.vmail_status_id;

      if (lastMailStatusId === 3) {
        SwalAlert.fire({
          icon: "info",
          title: "Info!",
          text: "This V-mail has been closed. If your issue still persists please reply on this mail. If you have another query unrelated to this mail please send a new Vmail.",
        });
      }
    }
  }, []);

  const sendMessage = (e) => {
    if (!message.body) {
      WishToaster({
        toastMessage: "Message is mandatory",
        toastType: "error",
      });
      return;
    }
    setLoading(true);
    let payload = new FormData();
    payload.append("distributor_id", message.distributor_id);
    payload.append("parent_vmail_id", message.parent_vmail_id);
    payload.append("subject_id", message.subject_id);
    payload.append("subject_title", message.subject_title);
    payload.append("body", message.body);
    payload.append("has_attachment", message.attachments.length > 0 ? 1 : 0);
    for (let i = 0; i < message.attachments.length; i++) {
      payload.append("attachments", message.attachments[i] ? message.attachments[i] : null);
    }
    message.has_attachment = message.attachments.length > 0 ? 1 : 0;
    postGeneralFormdata(
      payload,
      2,
      (data) => {
        console.log(data);
        setLoading(false);
        setMessage({ ...message, body: "", has_attachment: 0, attachments: [] });
        WishToaster({
          toastMessage: "Mail sent!",
          toastType: "success",
        });
        getMailTrail();
        setResetFilesFlag(Math.random());
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const handleAttachment = (attachment) => {
    window.open(`${attachment?.attachment_url}`, "_blank");
  };

  const getMailTrail = () => {
    let detailPayload = { parent_vmail_id: selectedMail?.parent_vmail_id, distributor_id: JSON.parse(localStorage.getItem("distributor"))?.distributor_id };
    postGeneral(
      3,
      detailPayload,
      (data) => {
        setMailDetail(data);
      },
      (error) => {}
    );
  };

  return (
    <div className="card shadow-none border border-light">
      <div className="card-body">
        <span className="d-flex justify-content-space-between align-items-center mb-3">
          <span className="d-flex align-items-center">
            <i
              className="las la-arrow-left"
              style={{ fontSize: "40px", cursor: "pointer" }}
              onClick={(e) => {
                getMails();
                setIsThreadView(false);
              }}
            ></i>
            <h4 className="ml-1">{mailDetail[0]?.title}</h4>
          </span>
        </span>
        {mailDetail.map((item) =>
          item.is_log ? (
            <span key={item?.vmail_id}>
              <div className="d-flex justify-content-space-between my-2">
                <div></div>
                <h5 className="text-center badge rounded-pill m-0 text-bg-secondary">{item.body}</h5>
                <span className="media-meta float-right">{AppUtils.formatDateTime(item?.create_time)}</span>
              </div>
              <hr />
            </span>
          ) : (
            <span key={item?.vmail_id}>
              <div className="media mb-2">
                <img src="../../../assets/app-assets/images/portrait/PlaceHolder.png" className="rounded-circle mr-2 mail-img shadow" alt="sender_image" />
                <div className="media-body">
                  <span className="media-meta float-right">{AppUtils.formatDateTime(item?.create_time)}</span>
                  <h5 className=" m-0">{item?.from_name}</h5>
                  <small className="text-muted">to : {item?.to_name}</small>
                </div>
              </div>
              <div style={{ marginLeft: "5rem" }} className="mb-2">
                <p>{item.body}</p>
                {item?.attachments?.length > 0 ? (
                  <span>
                    <h5 className="my-1">
                      <i className="las la-paperclip"></i> Attachments <span>{`(${item?.attachments?.length})`}</span>
                    </h5>
                    {item?.attachments?.map((attachment) => (
                      <h6
                        key={attachment?.attachment_file_name}
                        className="d-flex align-items-center"
                        style={{ color: "#6967ce", cursor: "pointer" }}
                        onClick={(e) => {
                          handleAttachment(attachment);
                        }}
                      >
                        <i className="las la-file"></i>
                        <span>{attachment?.attachment_file_name}</span>
                      </h6>
                    ))}
                  </span>
                ) : null}
              </div>
              <hr />
            </span>
          )
        )}

        <div className="media mt-3">
          <img src="../../../assets/app-assets/images/portrait/PlaceHolder.png" className="rounded-circle mr-1 mail-img shadow" alt="sender_image" />
          <div className="media-body">
            <textarea
              className="wysihtml5 form-control"
              rows="9"
              value={message.body}
              onChange={(e) => {
                setMessage({ ...message, body: e.target.value });
              }}
              placeholder="Reply here..."
            ></textarea>
            <div className="form-group row mt-1">
              <div className="col-sm-6">
                <WishUploadFiles
                  hideUploadButton
                  addButtonTitle="Add Attachment"
                  resetFilesFlag={resetFilesFlag}
                  onChange={(files) => {
                    setMessage({ ...message, attachments: files });
                  }}
                ></WishUploadFiles>
              </div>
            </div>
          </div>
        </div>
        <div className="text-right">
          <button type="button" className="btn btn-primary waves-effect waves-light mt-1" disabled={loading} onClick={sendMessage}>
            {loading ? (
              <>
                {/* <div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div> */}
                <span>Sending...</span>
              </>
            ) : (
              <>
                <i className="la la-send"></i>
                <span style={{ marginLeft: "5px" }}>Send</span>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
